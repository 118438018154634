export enum PaymentStatus {
  notCreated = 'notCreated',
  open = 'open',
  canceled = 'canceled',
  pending = 'pending',
  authorized = 'authorized',
  expired = 'expired',
  failed = 'failed',
  paid = 'paid',
  archived = 'archived',
}
