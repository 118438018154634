















import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import PaymentsTable from '../components/PaymentsTable.vue';
import BaseHeader from '../components/BaseHeader.vue';

import { Filter } from '../shared/types/filter.class';
import { SortOrder } from '../shared/types/sort-order.enum';
import { PaymentStatus } from '../api/payments/payment-status.enum';

// The @Component decorator indicates the class is a Vue component
@Component({ components: { PaymentsTable, BaseHeader } })
export default class Payments extends Vue {
  tabIndex = 0;

  filter: Filter = new Filter([], { createdAt: SortOrder.Asc });

  @Watch('tabIndex')
  onTabChanged(tabIndex: number) {
    this.filter.removeForKey('status');
    if (tabIndex === 1) {
      this.filter.addFilter('status', [
        PaymentStatus.open,
        PaymentStatus.notCreated,
      ]);
    } else if (tabIndex === 2) {
      this.filter.addFilter('status', [PaymentStatus.paid]);
    } else if (tabIndex === 3) {
      this.filter.addFilter('status', [
        PaymentStatus.pending,
        PaymentStatus.authorized,
      ]);
    } else if (tabIndex === 4) {
      this.filter.addFilter('status', [
        PaymentStatus.canceled,
        PaymentStatus.expired,
        PaymentStatus.failed,
      ]);
    } else if (tabIndex === 5) {
      this.filter.addFilter('status', [PaymentStatus.archived]);
    }
  }

  async created() {
    this.tabIndex = parseInt(this.$route.query.tab as string);
  }
}
