


























import Vue from 'vue';
import Component from 'vue-class-component';
import { namespace } from 'vuex-class';
import { Prop, Watch } from 'vue-property-decorator';

import BaseTable from '../components/BaseTable.vue';

import { Column } from '../shared/types/column.class';
import { Filter } from '../shared/types/filter.class';
import { SortOrder } from '../shared/types/sort-order.enum';

import { Payment } from '../api/payments/payment.model';
import Toast from '../shared/types/toast.class';
import { PaginateResult } from '../shared/types/paginate-result.class';
import { PaymentStatus } from '../api/payments/payment-status.enum';
import {
  formatDate,
  formatAssetDate,
  formatUser,
} from '../shared/util/print-column.util';

const paymentsModule = namespace('payments');
const appModule = namespace('app');

// The @Component decorator indicates the class is a Vue component
@Component({ components: { BaseTable } })
export default class PaymentsTable extends Vue {
  @Prop()
  filter!: Filter;

  @Prop({
    type: Array,
  })
  columns!: Column[];

  @Prop()
  embedded!: boolean;

  isLoading = false;
  baseColumns: Column[] = [
    new Column('status', 'Status', this.printStatus),
    new Column('booking.user.email', 'User'),
    new Column('booking.asset.name', 'Booking'),
    new Column('start', 'Booking start', this.formatAssetDateForPayment),
    new Column('end', 'Booking end', this.formatAssetDateForPayment),
    new Column(
      'booking.externalId',
      'Mollie ID',
      this.formatMollieLink,
      undefined,
      undefined,
      undefined,
      true,
    ),
  ];

  get getColumns() {
    if (!this.columns || this.columns.length === 0) {
      return this.baseColumns;
    }
    return this.columns;
  }

  @paymentsModule.Getter('pagination')
  pagination!: PaginateResult<Payment>;

  @paymentsModule.Getter('all')
  allPayments!: Payment[];

  @appModule.Mutation('addToast')
  addToast!: (toast: Toast) => void;

  @paymentsModule.Action('fetchAll')
  fetchAllPayments!: (filter?: Filter) => Promise<Payment[]>;

  @Watch('filter', { deep: true, immediate: true })
  onFilterChanged() {
    this.doFetchAllPayments();
  }

  onPageChanged(page: number) {
    this.filter.replaceFilter('page', page);
    this.doFetchAllPayments();
  }

  formatAssetDateForPayment(column: Column, payment: Payment) {
    return formatAssetDate(column, payment.booking);
  }

  formatMollieLink(column: Column, payment: Payment) {
    if (!payment.externalId) {
      return 'N/A';
    }
    return `<a target="_blank" href="https://www.mollie.com/dashboard/payments/${payment.externalId}">${payment.externalId}</a>`;
  }

  printStatus(column: Column, payment: Payment) {
    return this.$t(`paymentStatus.${payment.status}`);
  }

  doSort(field: string) {
    if (!this.filter.sort) {
      this.filter.sort = {};
    }
    const currentOrder = this.filter.sort[field];
    this.filter.sort = {};
    if (!currentOrder || currentOrder === SortOrder.Desc) {
      this.filter.sort[field] = SortOrder.Asc;
    } else if (currentOrder === SortOrder.Asc) {
      this.filter.sort[field] = SortOrder.Desc;
    }
    this.doFetchAllPayments();
  }

  onOpenPayment(payment: Payment) {
    const route = this.$router.resolve({
      name: 'PayPayment',
      params: { token: payment.token },
    });
    window.open(route.href, '_blank');
  }

  async doFetchAllPayments() {
    this.isLoading = true;
    await this.fetchAllPayments(this.filter);
    this.isLoading = false;
  }

  async created() {
    await this.doFetchAllPayments();
  }
}
